<template>
  <div
    class="centered"
    style="width: 100%; "
    :style="{
              background: `linear-gradient(${color}, black)`,
            }">
    <img
      v-lazy="` ${image}?w=315&h=200&${imgix_params}`"
      style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgb(0 0 0.6);
              border-radius: 0;
            "
      v-bind:alt="title"
      width="315px"
      height="200px"
      class=" d-inline-xs d-sm-none"

    />
    <img
      v-lazy="`${image}?w=615&h=400&${imgix_params}`"
      style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgb(0 0 0.6 );
              border-radius: 0;
            "
      v-bind:alt="title"
      width="615px"
      class=" d-none d-sm-inline"
      height="300px"
    />
    <div :class="align">
      <h1
        class="tulum-party-title "
        style="
             line-height: 1.2;
             text-transform: capitalize !important;
                margin: 20px;
                font-size: 1.8rem;
                padding: 20px;
              "
      >
        {{ title }}
      </h1>
      <p class="tulum-party-title "
         style="font-size: 0.8rem; margin: 20px;  padding: 20px;margin-top:-60px;font-weight: normal;text-transform:uppercase">
        {{caption }}
      </p>
    </div>

  </div>
</template>
<script>
export default {
  name: 'HeadImage',
  computed: {
    imgix_params: {
      get() {
        return this.clamp ? 'fit=clamp' : 'fit=fillmax&fill=blur'
      },
    },
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    clamp: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'text-left'
    },
    title: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
  }
}
</script>
