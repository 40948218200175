<template>
  <div class="card card-collapse text-left card-animation-on-hover" style="margin: 10px;">
    <router-link :to="{path: this.link}" >
      <img
        v-lazy=" image + '?q=&w=50&h=50&fit=clamp' "
        style="float:left;margin-right: 10px;z-index: 12; box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);border-radius: 360px;border: 1px solid #1b1f1f"
        v-bind:alt="this.title"
        height="50px"
        width="50px"
      />
      <div
        style="display: inline;color: white;position:relative ; line-height: 0.7rem">
        <span style="font-weight: 700 !important;">
          {{ title }}
        </span>
        <br>
        <small>{{ text }}</small>
      </div>
      <p style="font-size: 0.7rem" v-if="tags"> {{tags}}</p>
    </router-link>
  </div>
</template>
<script>

export default {
  name: 'MiniCard',
  components: {},
  props: ['image', 'link', 'title', 'text', 'newtab','tags'],

};
</script>

