<template>
  <div>
    <div class="card text-center">
      <router-link :to="{path: links[0].metadata.link_to}" v-if="links[0]">
        <div v-if="size === 'sm'">
          <img
            v-lazy="links[0].metadata.image.imgix_url+'?w=259&h=259&fit=clamp'" style="border-radius: 0" width="259px"
            height="259px">
          <div style="padding-left:20px;margin-top: -30px;background-color: rgba(0,0,0,0.6);border-radius: 0"
               class="text-left card btn-group-vertical">
            <p style="font-weight: bold;font-size: 1rem;line-height: 0.8;text-transform: capitalize">
              {{ links[0].metadata.text }}</p>
            <p style="margin-top: -8px;font-size: 0.7rem">{{ links[0].metadata.caption }}</p>
          </div>
        </div>

        <div v-if="size !== 'sm'" class="text-center">
          <img
            v-lazy="links[0].metadata.image.imgix_url+'?w=450&h=450&fit=clamp'" style="border-radius: 0" width="450px"
            height="450px" >
          <div style="padding-left:20px;margin-top: -60px;height:90px;background-color: rgba(0,0,0,0.6);border-radius: 0"
               class=" card text-left">
            <p style="font-weight: bold;font-size: 1.2rem;line-height: 0.8;text-transform: capitalize">
              {{ links[0].metadata.text }}</p>
            <p style="margin-top: -8px;font-size: 0.8rem">{{ links[0].metadata.caption }}</p>
          </div>
        </div>
      </router-link>
    </div>
    <div v-if="links">
      <div v-for="(link, idx) in links.slice(1, links.length)" :key="idx" style="margin: 10px" >
        <MiniCard
          :link="link.metadata.link_to"
          :title="link.metadata.text"
          :image="link.metadata.image.imgix_url"
          :tags="hashTags(link)"
          :text="link.metadata.caption"
        ></MiniCard>
      </div>
    </div>
  </div>
</template>
<script>
import MiniCard from '@/components/Cards/MiniCard';

export default {
  name: 'DynamicLinks',
  components: {MiniCard},
  methods: {
    hashTags(link) {
      return link.slug
        .split('-')
        .map((x) => `#${x}`)
        .join(' | ');
    },
  },
  data: () => {
    return {
      youTube:
        'https://imgix.cosmicjs.com/33d0d570-eaad-11ee-b074-b5c8fe3ef189-YouTube.jpg',
      soundCloud:
        'https://imgix.cosmicjs.com/9c356f90-eab2-11ee-b074-b5c8fe3ef189-Danleech-Simple-Soundcloud.1024.png',
      spotify:
        'https://imgix.cosmicjs.com/dab2a390-eab3-11ee-b074-b5c8fe3ef189-spotify.jpg',
    };
  },
  props: {
    links: {},
    size: {
      default: 'sm',
      type: String,
    },
  },
};
</script>
